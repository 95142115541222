<template>
	<div class="home">
		<el-drawer  :visible.sync="drawer" size="50%" direction="ltr" :show-close="false">
			<div>
				<div v-if="this.glogin()">
					<div class="sb_avatar">
						<img src="../assets/avatar.png" alt="">
					</div>
					<div class="sb_id c_up f12 mt2">ID: {{userinfo.id}}</div>
					<div class="sb_account">{{userinfo.username}}</div>
				</div>
				
				<!-- 功能列表 -->
				<div class="sb_item pt3">
					<div v-for="item of sidebarFilter" v-bind:key="item.id" @click="goto(item.router)">
						<el-row class="mt4">
							<el-col :span=6 class="f18">
								<i :class="item.icon"></i>
							</el-col>
							<el-col :span=18 class="tl f14">{{ item.name }}</el-col>
						</el-row>
					</div>
				</div>
				
				<!-- 退出/登录按钮 -->
				<div class="sb_btn pt5">
					<el-button type="primary" class="w80" v-if="this.glogin()" @click="logout()">{{$t("退出登录")}}</el-button>
					
					<el-button type="primary" class="w80" v-if="(!this.glogin())" @click="goto('/login')">{{$t("登入/注册")}}</el-button>
				</div>
			</div>
		</el-drawer>

		<div class="h_h bg_blue">
			<el-row type="flex" align="middle">
				<el-col :span=4>
					<img src="../assets/person.png" class="h_avatar" alt="" v-on:click="drawer = true">
				</el-col>
				<el-col :span=16>
					<el-input class="h_search" v-on:focus="showSearch()" style="" size="small" :placeholder="$t('搜索产品')"
						prefix-icon="el-icon-search" v-model="input">
					</el-input>
				</el-col>
				<el-col :span="3" class="tr">
					<div @click="$router.push('/alerts')">
						<i class="fa fa-bell-o f20 c_gray"></i>
					</div>					
				</el-col>
				<el-col :span="1">
					<div @click="$router.push('/alerts')">
						<div style="position:relative;width:20px;height:20px;line-height: 20px;top:-10px;left:-10px;" class="bg_yellow c_white br10 tc f12" v-if="alert_num>0">{{alert_num}}</div>
						<div style="position:relative;width:20px;height:20px;line-height: 20px;top:-10px;left:-10px;" class="bg_gray2 c_white br10 tc f12" v-if="alert_num==0">{{alert_num}}</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="h_imgs bg_white">
			<el-carousel height="190px">
				<el-carousel-item v-for="item in imgs" :key="item">
					<img :src="item" class="h_img" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>

		<!-- 公告 -->
		<div class="bg_white h_notice_c" style="display: none;">
			<div class="h_notice">
				<el-row>
					<el-col :span=3>
						<i class="fa fa-volume-up"></i>
					</el-col>
					<el-col :span=21>
						<el-carousel height="40px" direction="vertical" :autoplay="true" indicator-position="none">
							<el-carousel-item v-for="item in gglist" :key="item.id">
								<div class="medium" @click="goto('/content/'+item.id)">{{item.title}}</div>
							</el-carousel-item>
						</el-carousel>
					</el-col>
				</el-row>
			</div>
		</div>


		<div class="h_hots pt4 pb4 bg_white">
			<el-row>
				<el-col :span="8" v-for="item in coinlist" v-bind:key="item.id">
					<div @click="$router.push('/contract/'+item.id)">
						<img :src="item.logo" width="30px" alt="">
						<div class="fbold mt1">{{item.title}}</div>
						<div class="f14 mt2 c_up" v-if="item.type==0">{{item.price}}</div>
						<div class="f14 mt2 c_down" v-if="item.type==1">{{item.price}}</div>
						<div class="f14 mt2" v-if="item.type==2">{{item.price}}</div>
						<div class="mt1 c_down" v-if="item.type==1">{{item.change}}%</div>
						<div class="mt1 c_up" v-if="item.type==0">{{item.change}}%</div>
						<div class="mt1" v-if="item.type==2">0.00%</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="h_recharge mt3 mb3" @click="$router.push('/recharge_list')" style="display: none;">
			<el-row class="bg_white pt3 pb3" type="flex" align="middle">
				<el-col :span=6>
					<img src="../assets/recharge.png" width="50px" alt="">
				</el-col>
				<el-col :span=15 class="tl">
					<div class="fbold">{{$t("快速充币")}}</div>
					<div class="c_gray mt1">{{$t("支持BTC, USDT, ETH等")}}</div>
				</el-col>
				<el-col :span=3>
					<img src="../assets/recharge_arrow.png" width="30px" alt="">
				</el-col>
			</el-row>
		</div>

		<div class="h_tools mb3 bg_white mt3 pt4 pb2">
			<swiper ref="mySwiper" :options="swiperOptions">
				<swiper-slide>
					<div @click="$router.push('/recharge_list')">
						<div>
							<img src="../assets/recharge2.png" width="25px" alt="">
						</div>
						<div class="f12">{{ $t("充币") }}</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div  @click="$router.push('/withdraw')">
						<div>
							<img src="../assets/withdraw.png" width="25px" alt="">
						</div>
						<div class="f12">{{ $t("提币") }}</div>
					</div>
					
				</swiper-slide>
				
				<swiper-slide>
					<div @click="$router.push('/client_iframe')">
						<div>
							<img src="../assets/cs.png" width="25px" alt="">
						</div>
						<div class="f12">{{ $t("客户服务和数字商店") }}</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div @click="$router.push('/trade_history')">
						<div>
							<img src="../assets/bb.png" width="25px" alt="">
						</div>
						<div class="f12">{{ $t("持仓") }}</div>
					</div>
				</swiper-slide>
				<!-- <div class="swiper-pagination pt3" slot="pagination" style="position: inherit;"></div> -->
			</swiper>
		</div>

		<!-- 挖矿 -->
		<div class="h_dig mb3 bg_white mt3 pb2">
			<div class="p3">
				<swiper ref="mySwiper" :options="swiperOptions2">
					<swiper-slide>
						<!-- <router-link to="/miner"> -->
							<img class="w100" :src="dig_pic" alt="">
						<!-- </router-link> -->
					</swiper-slide>

					<!-- <swiper-slide>
						<img class="w100" src="../assets/dig.png" alt="">
					</swiper-slide> -->
					<!-- <div class="swiper-pagination pt3" id="sp2" slot="pagination" style="position: inherit;"></div> -->
				</swiper>
			</div>
		</div>

		<!-- 榜单 -->
		<div class="h_tops bg_white mt2">
			<el-tabs v-model="activeTab" :stretch=true class="m0">
				<el-tab-pane :label="this.$t('涨幅榜')" name="first">
					<el-row class="bg_body p3">
						<el-col :span=2>&nbsp;</el-col>
						<el-col :span=10 class="tl f12 c_gray">{{ $t("名称")}}</el-col>
						<el-col :span=6 class="tc f12 c_gray">{{ $t("最新价")}}</el-col>
						<el-col :span=6 class="tc f12 c_gray">{{ $t("涨跌幅")}}</el-col>
					</el-row>

					<div  @click="$router.push('/contract/'+item.id)" v-for="item in uplist" v-bind:key="item.id">
						<el-row class="bg_white p3" type="flex" align="middle">
							<el-col :span=3 class="tc">
								<div style="border-radius: 15px;width:30px;height:30px;display: flex;align-items: center;">
									<img :src="item.logo" width="30px" style="border-radius: 15px;" alt="">
								</div>
							</el-col>
							<el-col :span=9 class="tl">
								{{item.title}}
							</el-col>
							
							<el-col :span=6 class="c_up" v-if="item.change>=0">
								{{item.c}}
							</el-col>
							<el-col :span=6 class="c_down" v-if="item.change<0">
								{{item.c}}
							</el-col>
							
							<el-col :span=6>
								<span class="p2 c_white br10 bg_down f13" v-if="item.change<0">
									{{item.change}}%
								</span>
								<span class="p2 c_white br10 bg_up f13" v-if="item.change>=0">
									{{item.change}}%
								</span>
							</el-col>
						</el-row>
					</div>
				</el-tab-pane>
				
				
				<el-tab-pane :label="$t('成交额榜')" name="second">
					<el-row class="bg_body p3">
						<el-col :span=2>&nbsp;</el-col>
						<el-col :span=10 class="tl f12 c_gray">{{ $t("名称")}}</el-col>
						<el-col :span=6 class="tc f12 c_gray">{{ $t("最新价")}}</el-col>
						<el-col :span=6 class="tc f12 c_gray">{{ $t("成交额")}}</el-col>
					</el-row>
					
					<div  @click="$router.push('/contract/'+item.id)" v-for="item in vollist" v-bind:key="item.id">
						<el-row class="bg_white p3" type="flex" align="middle">
							<el-col :span=3 class="tc">
								<div style="border-radius: 15px;width:30px;height:30px;display: flex;align-items: center;">
									<img :src="item.logo" width="30px" style="border-radius: 15px;" alt="">
								</div>
							</el-col>
							<el-col :span=9 class="tl">
								{{item.title}}
							</el-col>
							
							<el-col :span=6 class="c_black2">
								{{item.c}}
							</el-col>
							
							<el-col :span=6>
								<span class="p2 c_white br10 bg_blue2 f12">
									${{item.vol_format}}
								</span>
							</el-col>
						</el-row>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import '../css/home.css';
	import {
		Swiper,
		SwiperSlide,
		directive
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'


	export default {
		name: 'HomeView',
		data() {
			return {
				input: '',
				search_text: this.$t("m.search_text"),
				imgs: [],
				swiperOptions: {
					height: 300,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
				swiperOptions2: {
					pagination: {
						el: '#sp2',
						clickable: true,
					}
				},
				activeTab: "first",
				drawer: false,
				sidebarItems: [
					{icon:"fa fa-user c_down", name:this.$t("个人中心"), router:"/personal",show:this.glogin()},
					{icon:"fa fa-key c_down", name:this.$t("修改密码"), router:"/password",show:this.glogin()},
					{icon:"fa fa-globe c_blue", name:this.$t("语言切换"), router:"/language",show:1},
					// {icon:"fa fa-credit-card c_down", name:this.$t("银行卡"), router:"bank",show:this.glogin()},
					// {icon:"fa fa-line-chart c_up", name:this.$t("投资统计"), router:"statistics",show:this.glogin()},
					{icon:"fa fa-headphones c_up", name:this.$t("联系客服"), router:"client_iframe",show:this.glogin()},
					{icon:"fa fa-dashboard c_up", name:this.$t("信用分"), router:"score",show:this.glogin()},
					{icon:"fa fa-question c_yellow", name:this.$t("帮助中心"), router:"/help",show:1},
					{icon:"fa fa-shield c_yellow", name:this.$t("安全中心"), router:"/safe",show:1},
					{icon:"fa fa-info c_up", name:this.$t("关于我们"), router:"/about",show:1},
					// {icon:"fa fa-download c_down", name:this.$t("下载"), router:"/download",show:1},
				],
				unlogin: !this.glogin(),
				gglist: [],
				coinlist: [],
				uplist: [],
				vollist: [],
				userinfo: {},
				dig_pic: this.GAPI+"/Public/Static/img/dig.jpg",
				alert_num: 0,
			}
		},
		computed: {
			sidebarFilter: function(){
				return this.sidebarItems.filter(function(row){
					return row.show
				})
			}
		},
		components: {
			Swiper,
			SwiperSlide,
		},
		directives: {
			swiper: directive
		},
		methods: {
			sidebar: function() {
				alert('ok');
			},

			showSearch: function() {
				this.$router.push('/search');
			},

			goto: function(path){
				if( path == '/download' ){
					this.download();
				}else{
					this.$router.push(path)
				}
				
			},
			
			logout: function(){
				// clean token
				localStorage.removeItem('token')
				this.$router.push('/login')
			},
			
			download: function(){
				// check platform
				let ua = navigator.userAgent.toLowerCase();
				let iphoneos = (ua.match(/iphone os/i) == "iphone os") || (ua.match(/iph os/i) == "iph os") || (ua.match(/ipad/i) == "ipad")
				// let android = (ua.match(/android/i) == "android") || (ua.match(/adr/i) == "Linux");
				
				if( iphoneos ){
					window.open(this.GAPI+'/download/app.mobileconfig');
				} else {
					window.open(this.GAPI+'/download/app.apk');
				}
			}
		},

		mounted() {
			var that = this;
			this.greq('get', '/main/banners', false, function(rs){
				that.imgs = rs.data;
			})
			
			this.greq('post', '/main/contents', {type:0}, function(rs){
				that.gglist = rs.data;
			})
			
			this.greq('get', '/main/recommand_coin', false, function(rs){
				that.coinlist = rs.data.recommand;
				
				let tmp = rs.data.tops.sort(function(a, b){
					return b.change - a.change;
				})
				that.uplist = tmp.slice(0, 16);
				
				tmp = rs.data.tops.sort(function(a, b){
					return b.vol - a.vol;
				})
				that.vollist = tmp.slice(0, 16);
			})
			
			if(this.glogin()){
				this.greq('get', '/api/userinfo', false, function(rs){
					if( rs.status ){
						that.userinfo = rs.data;
						that.alert_num = rs.data.alert_count;
					}
				})
			}
		},
	}
</script>


<style>
	.el-carousel__item {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		margin: 0;
		text-align: left;
	}

	.el-tabs__header {
		margin: 0 0;
	}
	
	.el-drawer__header {
		margin-bottom:0px;
	}
</style>
